import { clamp } from '../utilities'
import Controller from './controller'
import { preloadSound, playSound } from '../sound'

export default class extends Controller {
  initialize() {
    this.inner = this.element.parentNode
    this.container = this.inner.parentNode
    this.prevScrollY = 0
    this.spacer = document.createElement('div')
    this.container.appendChild(this.spacer)

    this.resize()
    this.resizeObserver = new ResizeObserver(this.resize)
    this.resizeObserver.observe(this.element)

    document.documentElement.classList.add('sticky-header')
  }

  resize = () => {
    this.headerHeight = this.element.getBoundingClientRect().height
    document.documentElement.style.setProperty('--header-height', `${this.headerHeight}px`);
    this.scroll(true)
  }

  scroll(isResize = false) {
    const scrollY = window.scrollY

    if (scrollY === 0 && this.direction !== 'DOWN') {
      this.lastPositionScrollY = null
      this.setDirectionDown(scrollY)
    } else if (scrollY < this.prevScrollY) {
      if (isResize === true || this.direction !== 'UP') {
        this.setDirectionUp(scrollY)
      }
    } else if (isResize === true || this.direction !== 'DOWN') {
      this.setDirectionDown(scrollY)
    }

    this.setHeaderOffset()

    this.prevScrollY = scrollY

    if (scrollY <= 0 || this.lastPositionScrollY <= 0 || this.lastPositionScrollY === this.headerHeight) {
      this.unsetIsScrolled()
    } else {
      this.setIsScrolled()
    }
  }

  setDirectionUp(scrollY) {
    this.direction = 'UP'

    if (window.hasOpenDropdown || (this.lastPositionScrollY != null && scrollY - this.lastPositionScrollY < this.headerHeight)) {
      scrollY = this.lastPositionScrollY + this.headerHeight
    }

    this.spacer.style.height = `calc(100% - ${scrollY}px)`
    this.lastPositionScrollY = scrollY
  }

  setDirectionDown(scrollY) {
    this.direction = 'DOWN'

    if (this.lastPositionScrollY != null && this.lastPositionScrollY - scrollY < this.headerHeight) {
      scrollY = Math.max(this.lastPositionScrollY - this.headerHeight, 0)
    }

    this.spacer.style.height = `calc(100% - ${scrollY}px - var(--header-height))`
    this.lastPositionScrollY = scrollY
  }

  setHeaderOffset() {
    let offset

    if (this.direction === 'UP') {
      offset = this.lastPositionScrollY - scrollY
    } else {
      offset = this.headerHeight + (this.lastPositionScrollY - scrollY)
    }

    offset = clamp(offset, 0, this.headerHeight)

    if (offset === this.offset) {
      return
    }
    this.offset = offset

    // Setting --header-offset at document level can cause scrolling jank in Safari
    // Instead, it's set here on the <main> element - which should contain any elements that use it in their styles
    const element = document.getElementById('main') || document.documentElement
    element.style.setProperty('--header-offset', `${offset}px`)
  }

  setIsScrolled() {
    if (this.isScrolled) return
    this.isScrolled = true

    this.element.classList.add('is-scrolled')
  }

  unsetIsScrolled() {
    if (!this.isScrolled) return
    this.isScrolled = false

    this.element.classList.remove('is-scrolled')
  }

  onHover(ev) {
    const action = ev.target.closest('a, button')

    if (action) {
      preloadSound(action.dataset.sound || '/static/sounds/soft-pen-in-navigation.mp3')
    }
  }

  onClick(ev) {
    const action = ev.target.closest('a, button')

    if (action) {
      playSound(action.dataset.sound || '/static/sounds/soft-pen-in-navigation.mp3')
    }
  }
}
